export enum GalaStripePayFields {

    _id = '_id',
    galaId = 'galaId',
    galaAttendeeId = 'galaAttendeeId',
    actionType = 'actionType',
    amount = 'amount',
    params = 'params',
    isCompleted = 'isCompleted',

    createdAt = 'createdAt',
    updatedAt = 'updatedAt',

    // Router
    galaStripePayId = 'galaStripePayId',
    paymentMethodId = 'paymentMethodId',
    paymentIntentId = 'paymentIntentId',

}

export enum StripePayActionType {

    raffle = 'raffle',
    donation = 'donation',

}
