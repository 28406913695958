export enum PathEnum {

    // Gala Group
    GALA = '/gala',
    GALATICKETSALE = '/gala/ticketSale',
    GALAATTENDEE = '/gala/attendee',
    GALASTRIPEPAY = '/gala/pay',
    GALARAFFLE = '/gala/raffle',
    GALADONATION = '/gala/donation',
    GALALIVE = '/gala/live',
    GALATABLE = '/gala/table',
    GALAAUCTION = '/gala/auction',

    // Order Group
    ORDER = '/order',
    ORDERROUND = '/order/round',
    ORDERITEM = '/order/round/item',
    CONSUMERREF = '/order/consumerRef',
    CUSTOMDISCOUNT = '/order/customDiscount',
    INAPPPAY = '/pay',

    // Payment Group
    TRANSACTION = '/transaction',

    // Store Group
    STORE = '/store',
    TABLE = '/store/tables',
    ITEM = '/store/item',
    OPTION = '/store/option',
    COMBO = '/store/combo',
    MENU = '/store/menu',
    CATEGORY = '/store/category',
    ACTIVESTAFFLIST = '/activeStaffList',
    STORESESHLIST = '/storeSeshList',
    SHIFTPLANNER = '/shiftPlanner',
    CREDITCODE = '/store/creditCode',
    CONSUMERPROFILE = '/store/consumerProfile',
    PRICEKEY = '/store/priceKey',
    STOREIMAGE = '/store/storeImage',
    STORESTAFF = '/store/staff',

    // Ingr grou
    INGRLOG = '/ingrLog',
    RAWMATLOG = '/rawMatLog',
    CORPRAWMAT = '/corpRawMat',
    RAWMATREQ = '/rawMatReq',

    MULTIPLIER = '/store/multiplier',

    // Corp Controller
    CORP = '/corp',
    CORPSTAFF = '/corp/staff',

    // Twilio Group
    TWIL = '/twil',

    // User Group
    STAFF = '/staff',
    CONSUMER = '/consumer',

    // Analytics
    STOREREPORT = '/storeReport',

}
