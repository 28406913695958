export enum GalaAttendeeFields {

    _id = '_id',
    registered = 'registered',
    ticketNum = 'ticketNum',
    galaId = 'galaId',
    galaTicketId = 'galaTicketId',
    galaTableId = 'galaTableId',
    galaRaffles = 'galaRaffles',
    galaDonations = 'galaDonations',
    fName = 'fName',
    pName = 'pName',
    lName = 'lName',
    address = 'address',
    postalCode = 'postalCode',
    city = 'city',
    province = 'province',
    country = 'country',
    email = 'email',
    phone = 'phone',
    mealChoice = 'mealChoice',
    dietRestriction = 'dietRestriction',
    seatingNotes = 'seatingNotes',
    notes = 'notes',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    accessCode = 'accessCode',

    // Router fields
    galaAttendeeTok = 'galaAttendeeTok',
    galaAttendee = 'galaAttendee',

    galaAttendeeId = 'galaAttendeeId',
    galaAttendeeIds = 'galaAttendeeIds',
    code = 'code',

}
