import { IonContent, IonPage } from '@ionic/react';
import { useContext } from 'react';
import { Factory } from '../../controllers/ControllerFactory';
import { GalaAttendeeContext, GalaAttendeeContextType } from '../../services/GalaAttendeeContext';
import mascotImg from '../../assets/mascot.png';
import welcomeImg from '../../assets/welcome.svg';
import './Home.scss';

const Home: React.FC = () => {
  const galaAttendeeController = Factory.GalaAttendeeController;
  const { galaAttendee, setGalaAttendee } = useContext(GalaAttendeeContext) as GalaAttendeeContextType;

  const handleLogout = () => {
    galaAttendeeController.logout(setGalaAttendee);
  }
  return (
    <IonPage>
      <IonContent fullscreen className="homeContent">

        <div className="container homeContainer">
          <img src={mascotImg} alt="mascot" className="mascot" />
          <div className="content">
            <img src={welcomeImg} alt="Presenting" className="welcome" />
            <p className="ticketNum">{`Ticker Number: ${galaAttendee!.ticketNum}`}</p>
            <h1>Welcome {galaAttendee!.fName} {galaAttendee!.lName},</h1>
            <h2>Table Number</h2>
            <h3>{galaAttendee!.tableName ? galaAttendee!.tableName :
              <span>Please contact a volunteer</span>}</h3>
            <h2>Paddle Number</h2>
            <h3>{galaAttendee!.auctionPaddle ? galaAttendee!.auctionPaddle :
              <span>Please contact a volunteer</span>}</h3>
            <button onClick={handleLogout}>Logout</button>
          </div>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
