import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React from 'react';

interface CheckoutFormProps {
    paymentMethodId: string | undefined;
    clientSecret: string;
    handleContinue: () => Promise<void>;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
    handleContinue, clientSecret, paymentMethodId,
}) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        if (paymentMethodId !== undefined) {
            const confirmPayment = await stripe.confirmCardPayment(clientSecret);
            if (confirmPayment.error !== undefined) {
                console.log('error', confirmPayment.error);
                return;
            }

            handleContinue();
        } else {

            const confirmPayment = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: 'http://sdcfund.org',
                },
                redirect: 'if_required'
            });

            if (confirmPayment.error !== undefined) {
                console.log('error', confirmPayment.error);
                return;
            }

            handleContinue();
        }
    };

    return (
        <div className="checkoutForm">
            {paymentMethodId === undefined && <PaymentElement />}
            <button
                disabled={!stripe}
                type="submit"
                onClick={handleSubmit}
            >
                Confirm Payment
            </button>
        </div>
    )
};

export default CheckoutForm;