import { Config } from '../../Config';
import { AxiosController } from '../AxiosController';
import { PathEnum } from '../PathEnum';
import { IGalaLiveResponse } from '../../models/galaGroup/galaLive/iGalaLive';
export class GalaLiveController extends AxiosController {
    private PATH: string = `${Config.REMOTE_SERVER_ADDR}${PathEnum.GALALIVE}`;

    public async getGalaLive(
    ): Promise<IGalaLiveResponse> {
        const galaLiveResponse = await this.axiosGET<IGalaLiveResponse>(
            `${this.PATH}/`,
            {}
        );

        return galaLiveResponse;
    }

}
