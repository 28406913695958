import React, { useState } from 'react';
import { IGalaAttendee } from '../models/galaGroup/galaAttendee/iGalaAttendee';


export type GalaAttendeeContextType = {
    galaAttendee: IGalaAttendee | null;
    setGalaAttendee: (galaAttendee: IGalaAttendee | null) => void;
};

export const GalaAttendeeContext = React.createContext<GalaAttendeeContextType | null>(null);

export const GalaAttendeeProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [galaAttendee, saveGalaAttendee] = useState<IGalaAttendee | null>(null);
    const setGalaAttendee = (galaAttendee: IGalaAttendee | null) => saveGalaAttendee(galaAttendee);

    return (
        <GalaAttendeeContext.Provider value={{ galaAttendee, setGalaAttendee }}>
            {children}
        </GalaAttendeeContext.Provider>
    );
};