import { GalaAttendeeController } from "./galaAttendee/galaAttendeeController";
import { GalaStripePayController } from "./galaStripePay/galaStripePayController";
import { GalaLiveController } from "./galaLive/galaLiveController";
import { GalaAuctionController } from "./galaAuction/galaAuctionController";

export class ControllerFactory {
    public GalaAttendeeController: GalaAttendeeController = new GalaAttendeeController();
    public GalaStripePayController: GalaStripePayController = new GalaStripePayController();
    public GalaLiveController: GalaLiveController = new GalaLiveController();
    public GalaAuctionController: GalaAuctionController = new GalaAuctionController();
}

export const Factory: ControllerFactory = new ControllerFactory();
