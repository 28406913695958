import { IonContent, IonPage } from '@ionic/react';
import mascotImg from '../../assets/mascot.png';
import posterImg from '../../assets/poster.png';
import './Sponsors.scss';

const Sponsors: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen className="sponsorsContent">
        <div className="container sponsorsContainer">
          <img src={mascotImg} alt="mascot" className="mascot" />
          <div className="content">
            <header>
              Sponsors
            </header>
            <img src={posterImg} alt="poster" className="poster" />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Sponsors;
