import { Config } from '../../Config';
import { AxiosController } from '../AxiosController';
import { PathEnum } from '../PathEnum';
import { IGalaAuctionArrayResponse, IGalaAuctionResponse } from '../../models/galaGroup/galaAuction/iGalaAuction';
import { AuctionTypeEnum, GalaAuctionFields } from '../../models/galaGroup/galaAuction/galaAuctionFields';
export class GalaAuctionController extends AxiosController {
    private PATH: string = `${Config.REMOTE_SERVER_ADDR}${PathEnum.GALAAUCTION}`;

    public async get(
        galaAuctionId: string,
    ): Promise<IGalaAuctionResponse> {
        const galaAuctionResponse = await this.axiosGET<IGalaAuctionResponse>(
            `${this.PATH}`,
            {
                [GalaAuctionFields.galaAuctionId]: galaAuctionId,
            }
        );

        return galaAuctionResponse;
    }

    public async getAll(
    ): Promise<IGalaAuctionArrayResponse> {
        const galaAuctionArrResponse = await this.axiosGET<IGalaAuctionArrayResponse>(
            `${this.PATH}/all`,
            {
                [GalaAuctionFields.auctionType]: AuctionTypeEnum.silent,
            }
        );

        return galaAuctionArrResponse;
    }

    public async getByUniqueLabel(
        uniqueLabel: string,
    ): Promise<IGalaAuctionResponse> {
        const galaLiveResponse = await this.axiosGET<IGalaAuctionResponse>(
            `${this.PATH}/uniqueLabel`,
            {
                [GalaAuctionFields.uniqueLabel]: uniqueLabel
            }
        );

        return galaLiveResponse;
    }

    public async placeBid(
        galaAuctionId: string,
        galaAttendeeId: string,
        amount: number,
    ): Promise<IGalaAuctionResponse> {
        const galaAuctionResponse = await this.axiosPOST<IGalaAuctionResponse>(
            `${this.PATH}/bid`,
            {
                [GalaAuctionFields.galaAuctionId]: galaAuctionId,
                [GalaAuctionFields.galaAttendeeId]: galaAttendeeId,
                [GalaAuctionFields.amount]: amount,
            }
        );

        return galaAuctionResponse;
    }

}
