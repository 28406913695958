// @ts-ignore
import { Storage, Drivers } from "@ionic/storage";

let storage: Storage;

export const createStorage = async (name: string) => {

    storage = new Storage({

        name,
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    });

    await storage.create();
}


export const setToStorage = async (key: string, val: any) => {

    await storage.set(key, val);
}

export const getFromStorage = async (key: string) => {

    const val = await storage.get(key);
    return val;
}

export const removeKeyFromStorage = async (key: string) => {

    await storage.remove(key);
}

export const clearStorage = async () => {

    await storage.clear();
}