import { IonContent, IonPage } from '@ionic/react';
import { useContext, useState } from 'react';
// @ts-ignore
import sdcLogo from '../../assets/logo.svg';
import qrcode from '../../assets/qrcode.svg';
import mascotImg from '../../assets/mascot.png';
import { Factory } from '../../controllers/ControllerFactory';
import { ToastController } from '../../controllers/ToastController';
import { GalaAttendeeContext, GalaAttendeeContextType } from '../../services/GalaAttendeeContext';
import './Login.scss';


import { QrReader } from 'react-qr-reader';

interface QrscanProps {
  handleLogin: (accessCode: string) => void;
}

const Qrscan: React.FC<QrscanProps> = ({
  handleLogin
}) => {

  const handleScan = (result: any) => {
    if (result) {
      handleLogin(`${result}`)
    }
  }

  return (
    <QrReader
      scanDelay={500}
      // style={previewStyle}
      // onError={handleError}
      onResult={handleScan}
      className="test"
      constraints={{
        facingMode: "environment"
      }}
    />

  );
}
const Login: React.FC = () => {
  // Context
  const { setGalaAttendee } = useContext(GalaAttendeeContext) as GalaAttendeeContextType;
  // Controllers
  const galaAttendeeController = Factory.GalaAttendeeController;

  // States
  const [code, setCode] = useState("");
  const changeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCode(value);
  }
  const [OLSLoading, setOLSLoading] = useState(false);
  const handleLogin = async (accessCode: string) => {
    if (!accessCode.includes("%SP_CODE%")) return;
    if (OLSLoading) return;
    setOLSLoading(true);
    try {
      await galaAttendeeController.login(accessCode, setGalaAttendee);
    } catch (err: any) {
      alert('Invalid access code.');
      ToastController.error(err.response.data.message);
    }
    setOLSLoading(false);
  }

  const [showScanner, setShowScanner] = useState(false);

  return (
    <IonPage>
      <IonContent fullscreen className="loginContent" scrollY>
        <div className="container loginContainer">
          <img src={sdcLogo} alt="logo" className="sdcLogo" />
          <h2>Empowering Youth to Empower Children with Disabilities</h2>
          {/* <div className="loginContent">
            <img src={mascotImg} alt="mascotImg" className="mascot" />
            <form className="getCode" onSubmit={handleGetCode}>
              <input type="text" className="phoneInput" value={phone} onChange={changePhone} placeholder="Phone Number" />
              <button type="submit" className="">get code</button>
            </form>

            <form className="login" onSubmit={onLoginSubmit}>
              <input type="text" className="codeInput" value={code} onChange={changeCode} placeholder="Code" />
              <button type="submit" className="" disabled={loginDisabled}>login</button>
            </form>

          </div> */}
          {/* <Qrscan /> */}
          <div className="loginContent">

            <div className="qrCodeContainer" onClick={() => setShowScanner(true)}>
              {!showScanner && (
                <>
                  <img src={qrcode} alt="qrcode" className="qr" />
                  <h5>Press to scan QR code to login</h5>
                  <img src={mascotImg} alt="mascotImg" className="mascot" />
                </>
              )}
              {showScanner && (
                <Qrscan handleLogin={handleLogin} />
              )}
            </div>
            <div className="or">
              <div className="line" />
              <span>OR</span>
              <div className="line" />
            </div>

            <div className="manualLogin">
              <div className="inputWrap">
                <input placeholder="Enter login code" value={code} onChange={changeCode} />
              </div>
              <button onClick={() => handleLogin(`%SP_CODE%${code}`)}>login</button>
            </div>

          </div>
        </div>
      </IonContent>
    </IonPage >
  );
};

export default Login;
