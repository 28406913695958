/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonApp,
  IonIcon, IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import { useContext, useEffect } from 'react';
import { Factory } from './controllers/ControllerFactory';
import { GalaAttendeeFields } from './models/galaGroup/galaAttendee/galaAttendeeFields';
import Auction from './pages/Auction/Auction';
import Donations from './pages/Donations/Donations';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import { GalaAttendeeContext, GalaAttendeeContextType, GalaAttendeeProvider } from './services/GalaAttendeeContext';
import { createStorage, getFromStorage } from './services/IonicStorage';
import './theme/variables.css';

import './App.scss';
import Sponsors from './pages/Sponsors/Sponsors';

import auctionIcon from './assets/footer/auction.svg';
import donationIcon from './assets/footer/donation.svg';
import homeIcon from './assets/footer/home.svg';
import sponsorIcon from './assets/footer/sponsor.svg';

setupIonicReact();

const AppWrapper: React.FC = () => {
  return (
    <GalaAttendeeProvider>
      <App />
    </GalaAttendeeProvider>
  )
}

const App: React.FC = () => {
  const galaAttendeeController = Factory.GalaAttendeeController;

  const { galaAttendee, setGalaAttendee } = useContext(GalaAttendeeContext) as GalaAttendeeContextType;
  useEffect(() => {
    const setupStore = async () => {
      await createStorage("uecoDB");
      const ttUser = await getFromStorage(GalaAttendeeFields.galaAttendee);
      if (ttUser === null) return;
      try {
        const galaAttendeeResponse = await galaAttendeeController.verify(ttUser._id);
        setGalaAttendee(galaAttendeeResponse.data.galaAttendee);
      } catch (error) {
        setGalaAttendee(null);
      }
    }
    setupStore();
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        {galaAttendee === null && (
          <Login />
        )}
        {galaAttendee !== null && (
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/home">
                <Home />
              </Route>
              <Route exact path="/auction">
                <Auction />
              </Route>
              <Route path="/donations">
                <Donations />
              </Route>
              <Route path="/sponsors">
                <Sponsors />
              </Route>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
            </IonRouterOutlet>


            <IonTabBar slot="bottom">
              <IonTabButton tab="home" href="/home">
                <IonIcon icon={homeIcon} />
              </IonTabButton>
              <IonTabButton tab="auction" href="/auction">
                <IonIcon icon={auctionIcon} />
              </IonTabButton>
              <IonTabButton tab="donations" href="/donations">
                <IonIcon icon={donationIcon} />
              </IonTabButton>
              <IonTabButton tab="sponsors" href="/sponsors">
                <IonIcon icon={sponsorIcon} />
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        )}
      </IonReactRouter>
    </IonApp>
  )
};

export default AppWrapper;
