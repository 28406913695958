export enum GalaAuctionFields {

    _id = '_id',
    galaId = 'galaId',
    uniqueLabel = 'uniqueLabel',
    auctionType = 'auctionType',
    auctionStatus = 'auctionStatus',
    name = 'name',
    desc = 'desc',
    imgUrl = 'imgUrl',
    startPrice = 'startPrice',
    currPrice = 'currPrice',
    minIncrease = 'minIncrease',
    winningBidId = 'winningBidId',
    galaAuctionBids = 'galaAuctionBids',

    createdAt = 'createdAt',
    updatedAt = 'updatedAt',

    // Router
    galaAuctionId = 'galaAuctionId',
    galaAttendeeId = 'galaAttendeeId',
    amount = 'amount',

}

export enum AuctionStatusEnum {

    unopen = -1,
    open = 0,
    closed = 1,

}

export enum AuctionTypeEnum {

    silent = 'silent',
    live = 'live',

}
