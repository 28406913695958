import { toast } from 'react-toastify';

export class ToastController {
    public static success(s: string) {
        toast.clearWaitingQueue();
        toast.success(s);
    }

    public static warning(s: string) {
        toast.clearWaitingQueue();
        toast.warning(s);
    }

    public static error(s: string) {
        toast.clearWaitingQueue();
        toast.error(s);
    }
}
