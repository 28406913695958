import { Config } from '../../Config';
import { GalaStripePayFields, StripePayActionType } from '../../models/galaGroup/galaStripePay/galaStripePayFields';
import { GalaStripePayActionResponse, GalaStripePayResponse, IStripePayParams } from '../../models/galaGroup/galaStripePay/IGalaStripePay';
import { AxiosController } from '../AxiosController';
import { PathEnum } from '../PathEnum';
export class GalaStripePayController extends AxiosController {
    private PATH: string = `${Config.REMOTE_SERVER_ADDR}${PathEnum.GALASTRIPEPAY}`;

    public async createStripePay(
        amount: number,
        galaAttendeeId: string,
        actionType: StripePayActionType,
        params: IStripePayParams,
        paymentMethodId?: string,
    ): Promise<GalaStripePayResponse> {
        const validResponse = await this.axiosPOST<GalaStripePayResponse>(
            `${this.PATH}/`,
            {
                [GalaStripePayFields.amount]: amount,
                [GalaStripePayFields.galaAttendeeId]: galaAttendeeId,
                [GalaStripePayFields.actionType]: actionType,
                [GalaStripePayFields.params]: params,
                [GalaStripePayFields.paymentMethodId]: paymentMethodId,
                isVolunteer: false
            }
        );

        return validResponse;
    }

    public async confirmPayment(
        galaStripePayId: string,
    ): Promise<GalaStripePayActionResponse> {
        const validResponse = await this.axiosPOST<GalaStripePayActionResponse>(
            `${this.PATH}/confirm`,
            {
                [GalaStripePayFields.galaStripePayId]: galaStripePayId,
            }
        );

        return validResponse;
    }


}
